/**
 *
 * Loading
 *
 */

import ReactLoading from 'react-loading';

export function Loading({ ...rest }) {
  return (
    <div>
      <ReactLoading {...rest} />
    </div>
  );
}
