/**
 *
 * Asynchronously loads the component for Title
 *
 */

import { lazyLoad } from 'app/components/Loadable';
import React from 'react';

import { Loading } from './components/Loading';

export const App = lazyLoad(
  () => import('./index'),
  module => module.App,
  {
    fallback: <Loading />,
  },
);
